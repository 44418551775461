// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function Select(Props) {
  var children = Props.children;
  var className = Props.className;
  var value = Props.value;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "control",
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: "select"
                }, React.createElement("select", {
                      value: value,
                      onChange: onChange
                    }, children)));
}

var make = Select;

exports.make = make;
/* react Not a pure module */
