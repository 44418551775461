// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

var columns = Css.style(/* :: */[
      Css.maxWidth(/* `percent */[
            -119887163,
            100
          ]),
      /* [] */0
    ]);

var Styles = {
  columns: columns
};

function Columns(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("div", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "columns",
                    /* :: */[
                      columns,
                      /* :: */[
                        Classnames$GearCalculator.unpack(className),
                        /* [] */0
                      ]
                    ]
                  ])
            }, children);
}

var make = Columns;

exports.Styles = Styles;
exports.make = make;
/* columns Not a pure module */
