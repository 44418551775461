// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var Helpers = {
  isInfo: "is-info",
  isSuccess: "is-success",
  isWarning: "is-warning",
  isDanger: "is-danger",
  isOutlined: "is-outlined",
  isPrimary: "is-primary",
  isLink: "is-link",
  isClearfix: "is-clearfix",
  isPulledLeft: "is-pulled-left",
  isPulledRight: "is-pulled-right",
  isMarginLess: "is-marginless",
  isPaddingLess: "is-paddingless",
  isOverlay: "is-overlay",
  isClipped: "is-clipped",
  isRadiusless: "is-radiusless",
  isShadowless: "is-shadowless",
  isUnselectable: "is-unselectable",
  isInvisible: "is-invisible",
  isHidden: "is-hidden",
  isSrOnly: "is-sr-only",
  isRelative: "is-relative",
  isSmall: "is-small",
  isNormal: "is-normal",
  isMedium: "is-medium",
  isLarge: "is-large",
  isFullWidth: "is-fullwidth",
  isDelete: "delete"
};

exports.Helpers = Helpers;
/* No side effect */
