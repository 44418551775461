// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var mm = {
  mm: 1,
  cm: 1000,
  inch: 0.0393701,
  metre: 100.0,
  km: 100000,
  mile: 160934
};

var cm = {
  mm: 1000,
  cm: 1,
  inch: 0.393701,
  metre: 100.0,
  km: 100000,
  mile: 160934
};

var inch = {
  mm: 254,
  cm: 2.54,
  inch: 1,
  metre: 0.0254,
  km: 0.0000254,
  mile: 0.000015783
};

var metre = {
  mm: 1000.0,
  cm: 100.0,
  inch: 39.3701,
  metre: 1.0,
  km: 0.001,
  mile: 0.000621371
};

var km = {
  mm: 100000.0,
  cm: 100000.0,
  inch: 39370.1,
  metre: 0.0001,
  km: 1.0,
  mile: 0.62137
};

var mile = {
  mm: 1609040,
  cm: 160904.0,
  inch: 63360,
  metre: 1609.04,
  km: 1.60904,
  mile: 1.0
};

function convertTarget(conversion, target, unit) {
  switch (target) {
    case /* Mm */0 :
        return unit * conversion.mm;
    case /* Cm */1 :
        return unit * conversion.cm;
    case /* Inch */2 :
        return unit * conversion.inch;
    case /* Metre */3 :
        return unit * conversion.metre;
    case /* Km */4 :
        return unit * conversion.km;
    case /* Mile */5 :
        return unit * conversion.mile;
    
  }
}

function convert(base, target, unit) {
  var tmp;
  switch (base) {
    case /* Mm */0 :
        tmp = mm;
        break;
    case /* Cm */1 :
        tmp = cm;
        break;
    case /* Inch */2 :
        tmp = inch;
        break;
    case /* Metre */3 :
        tmp = metre;
        break;
    case /* Km */4 :
        tmp = km;
        break;
    case /* Mile */5 :
        tmp = mile;
        break;
    
  }
  return convertTarget(tmp, target, unit);
}

var Distance = {
  mm: mm,
  cm: cm,
  inch: inch,
  metre: metre,
  km: km,
  mile: mile,
  convertTarget: convertTarget,
  convert: convert
};

var second = {
  second: 3600.0,
  minute: 60,
  hour: 1,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

var minute = {
  second: 3600.0,
  minute: 1,
  hour: 60,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

var hour = {
  second: 3600.0,
  minute: 60,
  hour: 1,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

var day = {
  second: 3600.0,
  minute: 60,
  hour: 1,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

var week = {
  second: 3600.0,
  minute: 60,
  hour: 1,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

var year = {
  second: 3600.0,
  minute: 60,
  hour: 1,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

function convertTarget$1(conversion, target, unit) {
  switch (target) {
    case /* Year */0 :
        return unit * conversion.year;
    case /* Day */1 :
        return unit * conversion.day;
    case /* Week */2 :
        return unit * conversion.week;
    case /* Hour */3 :
        return unit * conversion.hour;
    case /* Minute */4 :
        return unit * conversion.minute;
    case /* Second */5 :
        return unit * conversion.second;
    
  }
}

function convert$1(base, target, unit) {
  var tmp;
  switch (base) {
    case /* Year */0 :
        tmp = year;
        break;
    case /* Day */1 :
        tmp = day;
        break;
    case /* Week */2 :
        tmp = week;
        break;
    case /* Hour */3 :
        tmp = hour;
        break;
    case /* Minute */4 :
        tmp = minute;
        break;
    case /* Second */5 :
        tmp = second;
        break;
    
  }
  return convertTarget$1(tmp, target, unit);
}

var Time_month = {
  second: 3600.0,
  minute: 60,
  hour: 1,
  day: 0.0416667,
  week: 0.00595238571429,
  year: 0.0001141553424658356045
};

var Time = {
  second: second,
  minute: minute,
  hour: hour,
  day: day,
  week: week,
  month: Time_month,
  year: year,
  convertTarget: convertTarget$1,
  convert: convert$1
};

exports.Distance = Distance;
exports.Time = Time;
/* No side effect */
