// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Standard = require("reason-standard/bucklescript/src/Standard.bs.js");
var Menu$GearCalculator = require("../Bulma/Menu.bs.js");
var Bulma$GearCalculator = require("../Bulma/Bulma.bs.js");
var Button$GearCalculator = require("../Bulma/Button.bs.js");
var Column$GearCalculator = require("../Bulma/Column.bs.js");
var Navbar$GearCalculator = require("../Bulma/Navbar.bs.js");
var Columns$GearCalculator = require("../Bulma/Columns.bs.js");
var Gearing$GearCalculator = require("../GearUtil/Gearing.bs.js");
var MenuList$GearCalculator = require("../Bulma/MenuList.bs.js");
var MenuLabel$GearCalculator = require("../Bulma/MenuLabel.bs.js");
var GearListItem$GearCalculator = require("./GearListItem.bs.js");
var GearCalculator$GearCalculator = require("./GearCalculator.bs.js");

var sidebar = Css.style(/* :: */[
      Css.boxSizing(Css.borderBox),
      /* :: */[
        Css.padding(Css.rem(0.75)),
        /* :: */[
          Css.backgroundColor(Css.white),
          /* :: */[
            Css.unsafe("height", "calc(100vh - 52px)"),
            /* [] */0
          ]
        ]
      ]
    ]);

var menuList = Css.style(/* :: */[
      Css.paddingBottom(Css.rem(1.2)),
      /* [] */0
    ]);

var Styles = {
  sidebar: sidebar,
  menuList: menuList
};

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* AddGearing */0 :
        var newGearings = Standard.List.append(state.gearings, /* :: */[
              action[0],
              /* [] */0
            ]);
        return {
                gearings: newGearings,
                selected: Standard.List.length(newGearings) - 1 | 0
              };
    case /* RemoveGearing */1 :
        var gearing = action[0];
        var newGearings$1 = Standard.List.length(state.gearings) > 0 ? Standard.List.filter(state.gearings, (function (g) {
                  return Caml_obj.caml_notequal(g, gearing);
                })) : state.gearings;
        return {
                gearings: newGearings$1,
                selected: Standard.List.length(newGearings$1) - 1 | 0
              };
    case /* UpdateGearing */2 :
        var gearing$1 = action[0];
        return {
                gearings: Standard.List.map(state.gearings, (function (g) {
                        if (g.createdAt === gearing$1.createdAt) {
                          return gearing$1;
                        } else {
                          return g;
                        }
                      })),
                selected: state.selected
              };
    case /* SetSelectedGearing */3 :
        return {
                gearings: state.gearings,
                selected: action[0]
              };
    
  }
}

function renderGearListItems(gearings, selected, dispatch) {
  return Standard.List.toArray(Standard.List.mapI(gearings, (function (i, gearing) {
                    return React.createElement(GearListItem$GearCalculator.make, {
                                isActive: selected === i,
                                gearing: gearing,
                                handleView: (function (param) {
                                    return Curry._1(dispatch, /* SetSelectedGearing */Block.__(3, [i]));
                                  }),
                                handleRemove: (function (param) {
                                    return Curry._1(dispatch, /* RemoveGearing */Block.__(1, [gearing]));
                                  }),
                                isOnlyItem: Standard.List.length(gearings) === 1,
                                key: String(gearing.createdAt)
                              });
                  })));
}

function App(Props) {
  var match = React.useReducer(reducer, {
        gearings: /* :: */[
          Gearing$GearCalculator.make(/* () */0),
          /* [] */0
        ],
        selected: 0
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var selected = match$1.selected;
  var gearings = match$1.gearings;
  return React.createElement(React.Fragment, undefined, React.createElement(Navbar$GearCalculator.make, {
                  children: React.createElement("h1", {
                        className: "title has-text-light is-size-4"
                      }, "Gear Calculator")
                }), React.createElement(Columns$GearCalculator.make, {
                  children: null
                }, React.createElement(Column$GearCalculator.make, {
                      children: React.createElement(Menu$GearCalculator.make, {
                            children: null,
                            className: sidebar
                          }, React.createElement(Columns$GearCalculator.make, {
                                children: null
                              }, React.createElement(Column$GearCalculator.make, {
                                    children: React.createElement(MenuLabel$GearCalculator.make, {
                                          children: "Gearings"
                                        })
                                  }), React.createElement(Column$GearCalculator.make, {
                                    children: React.createElement(Button$GearCalculator.make, {
                                          children: "Add gearing",
                                          className: Bulma$GearCalculator.Helpers.isPulledRight,
                                          isPrimary: true,
                                          onClick: (function (param) {
                                              return Curry._1(dispatch, /* AddGearing */Block.__(0, [Gearing$GearCalculator.make(/* () */0)]));
                                            })
                                        })
                                  })), React.createElement(Columns$GearCalculator.make, {
                                children: React.createElement(Column$GearCalculator.make, {
                                      children: React.createElement(MenuList$GearCalculator.make, {
                                            children: renderGearListItems(gearings, selected, dispatch),
                                            className: menuList
                                          })
                                    })
                              })),
                      is3: true
                    }), React.createElement(Column$GearCalculator.make, {
                      children: React.createElement(GearCalculator$GearCalculator.make, {
                            gearing: Standard.$$Option.getUnsafe(Standard.List.getAt(gearings, selected)),
                            updateGear: (function (gearing) {
                                return Curry._1(dispatch, /* UpdateGearing */Block.__(2, [gearing]));
                              })
                          })
                    })));
}

var make = App;

exports.Styles = Styles;
exports.reducer = reducer;
exports.renderGearListItems = renderGearListItems;
exports.make = make;
/* sidebar Not a pure module */
