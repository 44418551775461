// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function Tags(Props) {
  var children = Props.children;
  var className = Props.className;
  var $staropt$star = Props.hasAddons;
  var hasAddons = $staropt$star !== undefined ? $staropt$star : false;
  return React.createElement("div", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "tags",
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* :: */[
                        Classnames$GearCalculator.ifTrue("has-addons", hasAddons),
                        /* [] */0
                      ]
                    ]
                  ])
            }, children);
}

var make = Tags;

exports.make = make;
/* react Not a pure module */
