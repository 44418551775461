// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function MenuLabel(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("p", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "menu-label",
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, children);
}

var make = MenuLabel;

exports.make = make;
/* react Not a pure module */
