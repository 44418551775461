// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Bulma$GearCalculator = require("./Bulma.bs.js");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function Button(Props) {
  var children = Props.children;
  var $staropt$star = Props.disabled;
  var className = Props.className;
  var $staropt$star$1 = Props.isPrimary;
  var $staropt$star$2 = Props.isLink;
  var $staropt$star$3 = Props.isInfo;
  var $staropt$star$4 = Props.isSuccess;
  var $staropt$star$5 = Props.isWarning;
  var $staropt$star$6 = Props.isDanger;
  var $staropt$star$7 = Props.isOutlined;
  var $staropt$star$8 = Props.isDelete;
  var $staropt$star$9 = Props.isSmall;
  var $staropt$star$10 = Props.isMedium;
  var $staropt$star$11 = Props.isLarge;
  var onClick = Props.onClick;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var isPrimary = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var isLink = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var isInfo = $staropt$star$3 !== undefined ? $staropt$star$3 : false;
  var isSuccess = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var isWarning = $staropt$star$5 !== undefined ? $staropt$star$5 : false;
  var isDanger = $staropt$star$6 !== undefined ? $staropt$star$6 : false;
  var isOutlined = $staropt$star$7 !== undefined ? $staropt$star$7 : false;
  var isDelete = $staropt$star$8 !== undefined ? $staropt$star$8 : false;
  var isSmall = $staropt$star$9 !== undefined ? $staropt$star$9 : false;
  var isMedium = $staropt$star$10 !== undefined ? $staropt$star$10 : false;
  var isLarge = $staropt$star$11 !== undefined ? $staropt$star$11 : false;
  return React.createElement("button", {
              className: Classnames$GearCalculator.make(/* :: */[
                    Classnames$GearCalculator.ifTrue("button", !isDelete),
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* :: */[
                        Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isPrimary, isPrimary),
                        /* :: */[
                          Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isLink, isLink),
                          /* :: */[
                            Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isInfo, isInfo),
                            /* :: */[
                              Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isSuccess, isSuccess),
                              /* :: */[
                                Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isWarning, isWarning),
                                /* :: */[
                                  Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isDanger, isDanger),
                                  /* :: */[
                                    Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isOutlined, isOutlined),
                                    /* :: */[
                                      Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isDelete, isDelete),
                                      /* :: */[
                                        Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isSmall, isSmall),
                                        /* :: */[
                                          Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isMedium, isMedium),
                                          /* :: */[
                                            Classnames$GearCalculator.ifTrue(Bulma$GearCalculator.Helpers.isLarge, isLarge),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              disabled: disabled,
              onClick: onClick
            }, children !== undefined ? Caml_option.valFromOption(children) : null);
}

var make = Button;

exports.make = make;
/* react Not a pure module */
