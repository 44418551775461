// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function Column(Props) {
  var children = Props.children;
  var className = Props.className;
  var $staropt$star = Props.isNarrow;
  var $staropt$star$1 = Props.is1;
  var $staropt$star$2 = Props.is2;
  var $staropt$star$3 = Props.is3;
  var $staropt$star$4 = Props.is4;
  var $staropt$star$5 = Props.is5;
  var $staropt$star$6 = Props.is6;
  var $staropt$star$7 = Props.is7;
  var $staropt$star$8 = Props.is8;
  var $staropt$star$9 = Props.is9;
  var $staropt$star$10 = Props.is10;
  var $staropt$star$11 = Props.is11;
  var $staropt$star$12 = Props.is12;
  var isNarrow = $staropt$star !== undefined ? $staropt$star : false;
  var is1 = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var is2 = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var is3 = $staropt$star$3 !== undefined ? $staropt$star$3 : false;
  var is4 = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var is5 = $staropt$star$5 !== undefined ? $staropt$star$5 : false;
  var is6 = $staropt$star$6 !== undefined ? $staropt$star$6 : false;
  var is7 = $staropt$star$7 !== undefined ? $staropt$star$7 : false;
  var is8 = $staropt$star$8 !== undefined ? $staropt$star$8 : false;
  var is9 = $staropt$star$9 !== undefined ? $staropt$star$9 : false;
  var is10 = $staropt$star$10 !== undefined ? $staropt$star$10 : false;
  var is11 = $staropt$star$11 !== undefined ? $staropt$star$11 : false;
  var is12 = $staropt$star$12 !== undefined ? $staropt$star$12 : false;
  return React.createElement("div", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "column",
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* :: */[
                        Classnames$GearCalculator.ifTrue("is-narrow", isNarrow),
                        /* :: */[
                          Classnames$GearCalculator.ifTrue("is-1", is1),
                          /* :: */[
                            Classnames$GearCalculator.ifTrue("is-2", is2),
                            /* :: */[
                              Classnames$GearCalculator.ifTrue("is-3", is3),
                              /* :: */[
                                Classnames$GearCalculator.ifTrue("is-4", is4),
                                /* :: */[
                                  Classnames$GearCalculator.ifTrue("is-5", is5),
                                  /* :: */[
                                    Classnames$GearCalculator.ifTrue("is-6", is6),
                                    /* :: */[
                                      Classnames$GearCalculator.ifTrue("is-7", is7),
                                      /* :: */[
                                        Classnames$GearCalculator.ifTrue("is-8", is8),
                                        /* :: */[
                                          Classnames$GearCalculator.ifTrue("is-9", is9),
                                          /* :: */[
                                            Classnames$GearCalculator.ifTrue("is-10", is10),
                                            /* :: */[
                                              Classnames$GearCalculator.ifTrue("is-11", is11),
                                              /* :: */[
                                                Classnames$GearCalculator.ifTrue("is-12", is12),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var make = Column;

exports.make = make;
/* react Not a pure module */
