// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Standard = require("reason-standard/bucklescript/src/Standard.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(cns) {
  return Standard.List.join(Standard.List.filter(cns, (function (x) {
                    return x !== "";
                  })), " ");
}

function ifTrue(cn, x) {
  if (x) {
    return cn;
  } else {
    return "";
  }
}

function ifSome(cn, x) {
  if (x !== undefined) {
    return cn;
  } else {
    return "";
  }
}

function mapSome(x, fn) {
  if (x !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(x));
  } else {
    return "";
  }
}

function unpack(param) {
  if (param !== undefined) {
    return param;
  } else {
    return "";
  }
}

exports.make = make;
exports.ifTrue = ifTrue;
exports.ifSome = ifSome;
exports.mapSome = mapSome;
exports.unpack = unpack;
/* Standard Not a pure module */
