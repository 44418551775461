// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function Card(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("aside", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "card",
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: "card-content"
                }, children));
}

var make = Card;

exports.make = make;
/* react Not a pure module */
