// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Tag$GearCalculator = require("./Tag.bs.js");
var Tags$GearCalculator = require("./Tags.bs.js");

function GearRatio(Props) {
  var chainringTeeth = Props.chainringTeeth;
  var cogTeeth = Props.cogTeeth;
  return React.createElement(Tags$GearCalculator.make, {
              children: null,
              hasAddons: true
            }, React.createElement(Tag$GearCalculator.make, {
                  children: chainringTeeth.toString(),
                  isInfo: true,
                  isLarge: true
                }), React.createElement(Tag$GearCalculator.make, {
                  children: "x",
                  isLarge: true
                }), React.createElement(Tag$GearCalculator.make, {
                  children: cogTeeth.toString(),
                  isInfo: true,
                  isLarge: true
                }));
}

var make = GearRatio;

exports.make = make;
/* react Not a pure module */
