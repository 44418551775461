// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Classnames$GearCalculator = require("../Utils/Classnames.bs.js");

function MenuList(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("ul", {
              className: Classnames$GearCalculator.make(/* :: */[
                    "menu-list",
                    /* :: */[
                      Classnames$GearCalculator.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, children);
}

var make = MenuList;

exports.make = make;
/* react Not a pure module */
