// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Standard = require("reason-standard/bucklescript/src/Standard.bs.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Unit$GearCalculator = require("../Utils/Unit.bs.js");

function make(param) {
  return {
          createdAt: Standard.$$Option.get(Standard.Float.toInt(Date.now()), 0),
          chainringTeeth: 46,
          cogTeeth: 16,
          wheelSize: 27,
          crankLength: 165,
          ambidextrousSkidder: false
        };
}

function isEvenFloat(x) {
  return x % 2.0 === 0.0;
}

function metreToKm(param) {
  return Unit$GearCalculator.Distance.convert(/* Metre */3, /* Km */4, param);
}

function metreToMile(param) {
  return Unit$GearCalculator.Distance.convert(/* Metre */3, /* Mile */5, param);
}

function inchToMetre(param) {
  return Unit$GearCalculator.Distance.convert(/* Inch */2, /* Metre */3, param);
}

function mmToInch(param) {
  return Unit$GearCalculator.Distance.convert(/* Mm */0, /* Inch */2, param);
}

function minuteToHour(param) {
  return Unit$GearCalculator.Time.convert(/* Minute */4, /* Hour */3, param);
}

function distancePerMinute(cadence, development) {
  return cadence * development;
}

function distancePerHour(cadence, development) {
  return minuteToHour(cadence * development);
}

function cadenceToSpeedKm(cadence, metresOfDevelopment) {
  var development = metreToKm(metresOfDevelopment);
  return minuteToHour(cadence * development);
}

function cadenceToSpeedMiles(cadence, metresOfDevelopment) {
  var development = metreToMile(metresOfDevelopment);
  return minuteToHour(cadence * development);
}

function equivalentGear(param) {
  var ratio = param.chainringTeeth / param.cogTeeth;
  var range = Standard.List.range(1, 100);
  return Standard.List.fold(range, /* [] */0, (function (gears, i) {
                var newRatio_numerator = ratio * Standard.Int.toFloat(i);
                var newRatio_denominator = 1.0 * Standard.Int.toFloat(i);
                var newRatio = {
                  numerator: newRatio_numerator,
                  denominator: newRatio_denominator
                };
                if (isEvenFloat(newRatio_numerator) && newRatio_numerator < 60 && newRatio_denominator > 10) {
                  return Standard.List.append(gears, /* :: */[
                              newRatio,
                              /* [] */0
                            ]);
                } else {
                  return gears;
                }
              }));
}

function gearInches(param) {
  return param.wheelSize * (param.chainringTeeth / param.cogTeeth);
}

function greatestCommonDivisor(_x, _y) {
  while(true) {
    var y = _y;
    var x = _x;
    if (y > 0.0) {
      _y = x % y;
      _x = y;
      continue ;
    } else {
      return x;
    }
  };
}

function lowestFraction(numerator, denominator) {
  var gcd = greatestCommonDivisor(numerator, denominator);
  return {
          numerator: numerator / gcd,
          denominator: denominator / gcd
        };
}

function metresOfDevelopment(gearing) {
  var gearMetre = inchToMetre(gearInches(gearing));
  return gearMetre * Standard.Float.pi;
}

function radiusRatio(wheelSize, crankLength) {
  return wheelSize / 2.0 / mmToInch(crankLength);
}

function gainRatio(param) {
  var ratio = radiusRatio(param.wheelSize, param.crankLength);
  return ratio * param.chainringTeeth / param.cogTeeth;
}

function generateCadences(gearing) {
  var development = metresOfDevelopment(gearing);
  return Standard.List.map(Standard.List.map(Standard.List.range(1, 15), (function (i) {
                    return Standard.Int.toFloat(Caml_int32.imul(i, 10));
                  })), (function (rpm) {
                var development$1 = metreToKm(development);
                var kmph = minuteToHour(rpm * development$1);
                var development$2 = metreToMile(development);
                var mph = minuteToHour(rpm * development$2);
                return {
                        rpm: rpm,
                        mph: mph,
                        kmph: kmph
                      };
              }));
}

exports.make = make;
exports.isEvenFloat = isEvenFloat;
exports.metreToKm = metreToKm;
exports.metreToMile = metreToMile;
exports.inchToMetre = inchToMetre;
exports.mmToInch = mmToInch;
exports.minuteToHour = minuteToHour;
exports.distancePerMinute = distancePerMinute;
exports.distancePerHour = distancePerHour;
exports.cadenceToSpeedKm = cadenceToSpeedKm;
exports.cadenceToSpeedMiles = cadenceToSpeedMiles;
exports.equivalentGear = equivalentGear;
exports.gearInches = gearInches;
exports.greatestCommonDivisor = greatestCommonDivisor;
exports.lowestFraction = lowestFraction;
exports.metresOfDevelopment = metresOfDevelopment;
exports.radiusRatio = radiusRatio;
exports.gainRatio = gainRatio;
exports.generateCadences = generateCadences;
/* Standard Not a pure module */
