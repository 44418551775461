// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Button$GearCalculator = require("../Bulma/Button.bs.js");
var Column$GearCalculator = require("../Bulma/Column.bs.js");
var Columns$GearCalculator = require("../Bulma/Columns.bs.js");
var GearRatio$GearCalculator = require("../Bulma/GearRatio.bs.js");

var viewButton = Css.style(/* :: */[
      Css.marginRight(Css.px(10)),
      /* [] */0
    ]);

var listItem = Css.style(/* :: */[
      Css.paddingBottom(Css.rem(0.6)),
      /* [] */0
    ]);

var buttons = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var selectionColumn = Css.style(/* :: */[
      Css.color(Css.hex("3ec46d")),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* :: */[
          Css.maxWidth(Css.px(30)),
          /* :: */[
            Css.fontWeight(/* bold */-1055161979),
            /* :: */[
              Css.fontSize(Css.em(2)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  viewButton: viewButton,
  listItem: listItem,
  buttons: buttons,
  selectionColumn: selectionColumn
};

function GearListItem(Props) {
  var $staropt$star = Props.isActive;
  var gearing = Props.gearing;
  var handleView = Props.handleView;
  var handleRemove = Props.handleRemove;
  var isOnlyItem = Props.isOnlyItem;
  var isActive = $staropt$star !== undefined ? $staropt$star : false;
  return React.createElement("li", {
              className: listItem
            }, React.createElement(Columns$GearCalculator.make, {
                  children: null
                }, React.createElement(Column$GearCalculator.make, {
                      children: isActive ? "*" : null,
                      className: selectionColumn
                    }), React.createElement(Column$GearCalculator.make, {
                      children: React.createElement(GearRatio$GearCalculator.make, {
                            chainringTeeth: gearing.chainringTeeth,
                            cogTeeth: gearing.cogTeeth
                          }),
                      isNarrow: true
                    }), React.createElement(Column$GearCalculator.make, {
                      children: null,
                      className: buttons
                    }, React.createElement(Button$GearCalculator.make, {
                          children: "View",
                          className: viewButton,
                          isSuccess: true,
                          onClick: (function (param) {
                              return Curry._1(handleView, /* () */0);
                            })
                        }), isOnlyItem ? null : React.createElement(Button$GearCalculator.make, {
                            isDelete: true,
                            isLarge: true,
                            onClick: (function (param) {
                                return Curry._1(handleRemove, /* () */0);
                              })
                          }))));
}

var make = GearListItem;

exports.Styles = Styles;
exports.make = make;
/* viewButton Not a pure module */
