// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Navbar(Props) {
  var children = Props.children;
  return React.createElement("nav", {
              "aria-label": "main navigation",
              className: "navbar is-dark",
              role: "navigation"
            }, React.createElement("div", {
                  className: "navbar-item"
                }, children));
}

var make = Navbar;

exports.make = make;
/* react Not a pure module */
