// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Standard = require("reason-standard/bucklescript/src/Standard.bs.js");

var chainringTeeth = Standard.List.map(Standard.List.range(28, 59), (function (i) {
        return Standard.Int.toFloat(i);
      }));

var cogTeeth = Standard.List.map(Standard.List.range(9, 23), (function (i) {
        return Standard.Int.toFloat(i);
      }));

var wheelSizes = /* :: */[
  {
    name: "29 inch (nominal)",
    value: 29,
    key: "29-nom"
  },
  /* :: */[
    {
      name: "28 inch (nominal)",
      value: 28,
      key: "28-nom"
    },
    /* :: */[
      {
        name: "27 inch (nominal)",
        value: 27,
        key: "27-nom"
      },
      /* :: */[
        {
          name: "26 inch (nominal)",
          value: 26,
          key: "26-nom"
        },
        /* :: */[
          {
            name: "700 X 56 / 56-622 / 29 inch",
            value: 29.13,
            key: "700-56"
          },
          /* :: */[
            {
              name: "700 X 50 / 50-622 / 29 inch",
              value: 28.94,
              key: "700-50"
            },
            /* :: */[
              {
                name: "700 X 44 / 44-622 / 29 inch",
                value: 27.86,
                key: "700-44"
              },
              /* :: */[
                {
                  name: "700 X 38 / 38-622",
                  value: 27.32,
                  key: "700-38"
                },
                /* :: */[
                  {
                    name: "700 X 35 / 35-622",
                    value: 27.17,
                    key: "700-35"
                  },
                  /* :: */[
                    {
                      name: "700 X 32 / 32-622 ",
                      value: 27,
                      key: "700-32"
                    },
                    /* :: */[
                      {
                        name: "700 X 28 / 28-622",
                        value: 26.76,
                        key: "700-28"
                      },
                      /* :: */[
                        {
                          name: "700 X 25 / 25-622",
                          value: 26.38,
                          key: "700-25"
                        },
                        /* :: */[
                          {
                            name: "700 X 23 / 23-622",
                            value: 26.28,
                            key: "700-23"
                          },
                          /* :: */[
                            {
                              name: "700 X 20 / 20-622",
                              value: 26.14,
                              key: "700-20"
                            },
                            /* :: */[
                              {
                                name: "Tubular / Wide",
                                value: 26.53,
                                key: "tub-wide"
                              },
                              /* :: */[
                                {
                                  name: "Tubular / Narrow",
                                  value: 26.38,
                                  key: "tub-narrow"
                                },
                                /* :: */[
                                  {
                                    name: "28 X 1 1/2 / 40-635 ",
                                    value: 28.15,
                                    key: "40-635"
                                  },
                                  /* :: */[
                                    {
                                      name: "27 X 1 3/8 / 35-630",
                                      value: 27.18,
                                      key: "35-630"
                                    },
                                    /* :: */[
                                      {
                                        name: "27 X 1 1/4 / 32-630",
                                        value: 27.08,
                                        key: "32-630"
                                      },
                                      /* :: */[
                                        {
                                          name: "27 X 1 1/8 / 28-630",
                                          value: 27,
                                          key: "28-630"
                                        },
                                        /* :: */[
                                          {
                                            name: "27 X 1 / 25-630",
                                            value: 26.88,
                                            key: "25-630"
                                          },
                                          /* :: */[
                                            {
                                              name: "26 X 2.35 / 60-559 / MTB",
                                              value: 26.41,
                                              key: "60-559"
                                            },
                                            /* :: */[
                                              {
                                                name: "26 X 2.125 / 54-559 / MTB",
                                                value: 25.94,
                                                key: "54-559"
                                              },
                                              /* :: */[
                                                {
                                                  name: "26 X 1.9 / 47-559 / MTB",
                                                  value: 25.75,
                                                  key: "47-559"
                                                },
                                                /* :: */[
                                                  {
                                                    name: "26 X 1.5 / 38-559 / MTB",
                                                    value: 24.87,
                                                    key: "38-559"
                                                  },
                                                  /* :: */[
                                                    {
                                                      name: "26 X 1.25 / 32-559 / MTB",
                                                      value: 24.47,
                                                      key: "32-559"
                                                    },
                                                    /* :: */[
                                                      {
                                                        name: "26 X 1.0 / 25-559 / MTB",
                                                        value: 23.97,
                                                        key: "25-559"
                                                      },
                                                      /* :: */[
                                                        {
                                                          name: "650 x 38b / 38-584 650B",
                                                          value: 26,
                                                          key: "38-584"
                                                        },
                                                        /* :: */[
                                                          {
                                                            name: "650 x 28C / 28-571 / 26' road/tri",
                                                            value: 24.7,
                                                            key: "28-571"
                                                          },
                                                          /* :: */[
                                                            {
                                                              name: "650 x 25C / 25-571 / 26' road/tri",
                                                              value: 24.46,
                                                              key: "25-571"
                                                            },
                                                            /* :: */[
                                                              {
                                                                name: "650 x 23C / 23-571 / 26' road/tri",
                                                                value: 24.31,
                                                                key: "23-571"
                                                              },
                                                              /* :: */[
                                                                {
                                                                  name: "26 X 1 3/8 / 35-590",
                                                                  value: 25.91,
                                                                  key: "35-590"
                                                                },
                                                                /* :: */[
                                                                  {
                                                                    name: "24 inch (nominal)",
                                                                    value: 24,
                                                                    key: "24-num"
                                                                  },
                                                                  /* :: */[
                                                                    {
                                                                      name: "24 x 1 / 25-520",
                                                                      value: 21.97,
                                                                      key: "25-520"
                                                                    },
                                                                    /* :: */[
                                                                      {
                                                                        name: "32-451 /20 x 1 3/8",
                                                                        value: 20.15,
                                                                        key: "32-451"
                                                                      },
                                                                      /* :: */[
                                                                        {
                                                                          name: "28-451/20 x 1 1/8",
                                                                          value: 19.9,
                                                                          key: "28-451"
                                                                        },
                                                                        /* :: */[
                                                                          {
                                                                            name: "20 X 1.75 / 44-406 / BMX",
                                                                            value: 18.68,
                                                                            key: "44-406"
                                                                          },
                                                                          /* :: */[
                                                                            {
                                                                              name: "20 X 1.25 / 32-406",
                                                                              value: 18.43,
                                                                              key: "32-406"
                                                                            },
                                                                            /* :: */[
                                                                              {
                                                                                name: "18 x 1.5 / 40-355",
                                                                                value: 17.16,
                                                                                key: "40-355"
                                                                              },
                                                                              /* :: */[
                                                                                {
                                                                                  name: "17 x 1 1/4 / 28-369",
                                                                                  value: 16.6,
                                                                                  key: "28-369"
                                                                                },
                                                                                /* :: */[
                                                                                  {
                                                                                    name: "16 x 1 1/2 / 40-349",
                                                                                    value: 16.88,
                                                                                    key: "40-349"
                                                                                  },
                                                                                  /* :: */[
                                                                                    {
                                                                                      name: "16 x 1 3/8 / 35-349",
                                                                                      value: 16.07,
                                                                                      key: "35-349"
                                                                                    },
                                                                                    /* :: */[
                                                                                      {
                                                                                        name: "16 x 1.5 / 37-305",
                                                                                        value: 13.46,
                                                                                        key: "37-305"
                                                                                      },
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var crankLengths = /* :: */[
  150,
  /* :: */[
    152,
    /* :: */[
      155,
      /* :: */[
        158,
        /* :: */[
          160,
          /* :: */[
            162.5,
            /* :: */[
              165,
              /* :: */[
                167.5,
                /* :: */[
                  170,
                  /* :: */[
                    172.5,
                    /* :: */[
                      175,
                      /* :: */[
                        177.5,
                        /* :: */[
                          180,
                          /* :: */[
                            185,
                            /* :: */[
                              190,
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

exports.chainringTeeth = chainringTeeth;
exports.cogTeeth = cogTeeth;
exports.wheelSizes = wheelSizes;
exports.crankLengths = crankLengths;
/* chainringTeeth Not a pure module */
