// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var App$GearCalculator = require("./Components/App.bs.js");

((require('bulma')));

Css.$$global("html, body", /* :: */[
      Css.overflow(Css.hidden),
      /* [] */0
    ]);

ReactDOMRe.renderToElementWithId(React.createElement(App$GearCalculator.make, { }), "app");

/*  Not a pure module */
