// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Standard = require("reason-standard/bucklescript/src/Standard.bs.js");
var Card$GearCalculator = require("../Bulma/Card.bs.js");
var Field$GearCalculator = require("../Bulma/Field.bs.js");
var Label$GearCalculator = require("../Bulma/Label.bs.js");
var Table$GearCalculator = require("../Bulma/Table.bs.js");
var Column$GearCalculator = require("../Bulma/Column.bs.js");
var Select$GearCalculator = require("../Bulma/Select.bs.js");
var Columns$GearCalculator = require("../Bulma/Columns.bs.js");
var Content$GearCalculator = require("../Bulma/Content.bs.js");
var Gearing$GearCalculator = require("../GearUtil/Gearing.bs.js");
var GearingValues$GearCalculator = require("../GearUtil/GearingValues.bs.js");

var container = Css.style(/* :: */[
      Css.padding(Css.rem(0.75)),
      /* [] */0
    ]);

var Styles = {
  container: container
};

function createGearing(gearing) {
  return {
          gearInches: Gearing$GearCalculator.gearInches(gearing),
          development: Gearing$GearCalculator.metresOfDevelopment(gearing),
          gainRatio: Gearing$GearCalculator.gainRatio(gearing),
          equivalentGears: Gearing$GearCalculator.equivalentGear(gearing),
          cadences: Gearing$GearCalculator.generateCadences(gearing)
        };
}

function GearCalculator(Props) {
  var gearing = Props.gearing;
  var updateGear = Props.updateGear;
  var details = createGearing(gearing);
  var updateChainring = function (e) {
    var value = e.target.value;
    var chainringTeeth = Standard.$$Option.get(Standard.Float.ofString(value), 1);
    return Curry._1(updateGear, {
                createdAt: gearing.createdAt,
                chainringTeeth: chainringTeeth,
                cogTeeth: gearing.cogTeeth,
                wheelSize: gearing.wheelSize,
                crankLength: gearing.crankLength,
                ambidextrousSkidder: gearing.ambidextrousSkidder
              });
  };
  var updateCog = function (e) {
    var value = e.target.value;
    var cogTeeth = Number(value);
    return Curry._1(updateGear, {
                createdAt: gearing.createdAt,
                chainringTeeth: gearing.chainringTeeth,
                cogTeeth: cogTeeth,
                wheelSize: gearing.wheelSize,
                crankLength: gearing.crankLength,
                ambidextrousSkidder: gearing.ambidextrousSkidder
              });
  };
  var updateWheelSize = function (e) {
    var value = e.target.value;
    var wheelSize = Standard.$$Option.get(Standard.Float.ofString(value), 1);
    return Curry._1(updateGear, {
                createdAt: gearing.createdAt,
                chainringTeeth: gearing.chainringTeeth,
                cogTeeth: gearing.cogTeeth,
                wheelSize: wheelSize,
                crankLength: gearing.crankLength,
                ambidextrousSkidder: gearing.ambidextrousSkidder
              });
  };
  var updateCrankLength = function (e) {
    var value = e.target.value;
    var crankLength = Standard.$$Option.get(Standard.Float.ofString(value), 1);
    return Curry._1(updateGear, {
                createdAt: gearing.createdAt,
                chainringTeeth: gearing.chainringTeeth,
                cogTeeth: gearing.cogTeeth,
                wheelSize: gearing.wheelSize,
                crankLength: crankLength,
                ambidextrousSkidder: gearing.ambidextrousSkidder
              });
  };
  return React.createElement("section", {
              className: container
            }, React.createElement(Card$GearCalculator.make, {
                  children: null
                }, React.createElement(Content$GearCalculator.make, {
                      children: React.createElement(Columns$GearCalculator.make, {
                            children: null
                          }, React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: React.createElement(Select$GearCalculator.make, {
                                            children: Standard.List.toArray(Standard.List.map(GearingValues$GearCalculator.chainringTeeth, (function (tooth) {
                                                        return React.createElement("option", {
                                                                    key: Standard.Float.toString(tooth)
                                                                  }, Standard.Float.toString(tooth));
                                                      }))),
                                            value: gearing.chainringTeeth.toString(),
                                            onChange: updateChainring
                                          }),
                                      label: "Chainring"
                                    })
                              }), React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: React.createElement(Select$GearCalculator.make, {
                                            children: Standard.List.toArray(Standard.List.map(GearingValues$GearCalculator.cogTeeth, (function (tooth) {
                                                        var toothString = Standard.Float.toString(tooth);
                                                        return React.createElement("option", {
                                                                    key: toothString,
                                                                    value: toothString
                                                                  }, toothString);
                                                      }))),
                                            value: Standard.Float.toString(gearing.cogTeeth),
                                            onChange: updateCog
                                          }),
                                      label: "Cog"
                                    })
                              }), React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: React.createElement(Select$GearCalculator.make, {
                                            children: Standard.List.toArray(Standard.List.map(GearingValues$GearCalculator.wheelSizes, (function (wheel) {
                                                        return React.createElement("option", {
                                                                    key: wheel.key,
                                                                    value: Standard.Float.toString(wheel.value)
                                                                  }, wheel.name);
                                                      }))),
                                            value: gearing.wheelSize.toString(),
                                            onChange: updateWheelSize
                                          }),
                                      label: "Wheel size"
                                    })
                              }), React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: React.createElement(Select$GearCalculator.make, {
                                            children: Standard.List.toArray(Standard.List.map(GearingValues$GearCalculator.crankLengths, (function (crank) {
                                                        var crankString = Standard.Float.toString(crank);
                                                        return React.createElement("option", {
                                                                    key: crankString,
                                                                    value: crankString
                                                                  }, crankString);
                                                      }))),
                                            value: Standard.Float.toString(gearing.crankLength),
                                            onChange: updateCrankLength
                                          }),
                                      label: "Crank length"
                                    })
                              }))
                    }), React.createElement("hr", undefined), React.createElement(Content$GearCalculator.make, {
                      children: React.createElement(Columns$GearCalculator.make, {
                            children: null
                          }, React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: details.gearInches.toFixed(1),
                                      label: "Gear inches"
                                    })
                              }), React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: details.development.toFixed(1),
                                      label: "Development"
                                    })
                              }), React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Field$GearCalculator.make, {
                                      children: details.gainRatio.toFixed(1),
                                      label: "Gain ratio"
                                    })
                              }))
                    }), React.createElement("hr", undefined), React.createElement(Content$GearCalculator.make, {
                      children: React.createElement(Columns$GearCalculator.make, {
                            children: null
                          }, React.createElement(Column$GearCalculator.make, {
                                children: null,
                                is6: true
                              }, React.createElement(Label$GearCalculator.make, {
                                    children: "Cadences"
                                  }), React.createElement(Table$GearCalculator.make, {
                                    children: null
                                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", undefined, "Cadence"), React.createElement("td", undefined, "Kmph"), React.createElement("td", undefined, "Mph"))), React.createElement("tbody", undefined, Standard.List.toArray(Standard.List.map(details.cadences, (function (param) {
                                                  return React.createElement("tr", undefined, React.createElement("td", undefined, Standard.Float.toString(Standard.Float.round(undefined, param.rpm))), React.createElement("td", undefined, param.kmph.toFixed(2)), React.createElement("td", undefined, param.mph.toFixed(2)));
                                                })))))), React.createElement(Column$GearCalculator.make, {
                                children: React.createElement(Label$GearCalculator.make, {
                                      children: "Equivalent Gears"
                                    }),
                                is6: true
                              }))
                    })));
}

var make = GearCalculator;

exports.Styles = Styles;
exports.createGearing = createGearing;
exports.make = make;
/* container Not a pure module */
